import './styles.css';

import React, { lazy, useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import * as ROUTES from 'Routes/routes';
import { connect, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getDealers } from 'Store/actions/DealerActions';
import { getParts } from 'Store/actions/PartsActions';
import { getBikes } from 'Store/actions/BikeActions';
import { getBicycles } from 'Store/actions/BicycleActions';
import { getServiceProviders } from 'Store/actions/ServiceProvActions';
import useAuthentication from 'Hooks/useAuthentication';
import PrivateRoute from 'Components/PrivateRoute/PrivateRoute';
import { LOGIN_GOOGLE_USER_FAILURE } from 'Store/Types';
import GoogleOneTapLogin from 'react-google-one-tap-login';
import useAuthenticationRequests from 'Hooks/useAuthenticationRequests';
import ReactGA from 'react-ga';
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALITYCS_ID);

const NewHeader = lazy(() => import('Components/Header/Header'));
const Blog = lazy(() => import('Pages/Blog') as any);
const Details = lazy(() => import('Pages/Details') as any);
const Garage = lazy(() => import('Pages/Garage') as any);
const Home = lazy(() => import('Pages/Home') as any);
const NewDealer = lazy(() => import('Pages/NewDealer') as any);
const OurHistory = lazy(() => import('Pages/OurHistory') as any);
const Post = lazy(() => import('Pages/Post') as any);
const SocialProgram = lazy(() => import('Pages/SocialProgram') as any);
const AfterSale = lazy(() => import('Pages/AfterSale') as any);
const Footer = lazy(() => import('Components/Footer/FooterIcons') as any);
const WhereBuyPage = lazy(
  () => import('Pages/WhereBuyPage/WhereBuyPage') as any
);
const UserPage = lazy(() => import('Pages/UserPage/UserPage') as any);
const BikeDetail = lazy(
  () => import('Pages/BikeDetailsPage/BikeDetailsPage') as any
);
const LoginUserPage = lazy(
  () => import('Pages/LoginUserPage/LoginUserPage') as any
);
const SparePartsPage = lazy(
  () => import('Pages/SparePartsPage/SparePartsPage')
);

interface IProps {
  getBikes: () => void;
  getBicycles: () => void;
  getDealers: () => void;
  getParts: () => void;
  getServiceProviders: () => void;
}

const AnimatedSwitch: React.FC<IProps> = ({
  getBikes,
  getBicycles,
  getDealers,
  getParts,
  getServiceProviders,
}) => {
  // change the footer in some windows
  const location = useLocation();
  const dispatch = useDispatch();
  const [horizontalFooter, setHorizontalFooter] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  useAuthenticationRequests();
  const {
    handleSendSessionDataTo,
    authState,
    user,
    handleOneTapLogin,
  } = useAuthentication();
  const history = useHistory();

  const onSuccessGOTL = (response) => {
    handleOneTapLogin(
      response.email,
      response.given_name + ' ' + response.family_name,
      response.aud
    );
  };

  const onErrorGOTL = (error) => {
    dispatch({
      type: LOGIN_GOOGLE_USER_FAILURE,
      payload: error?.toString(),
    });
  };

  useEffect(() => {
    getBikes();
    getBicycles();
    getParts();
    getServiceProviders();
    getDealers();
  }, []);

  useEffect(() => {
    setHorizontalFooter(
      location.pathname === ROUTES.HOME ||
        location.pathname === ROUTES.POSTVENTA ||
        location.pathname === ROUTES.HISTORY ||
        location.pathname === ROUTES.SOCIALPROGRAM ||
        location.pathname === ROUTES.SPARE_PARTS ||
        location.pathname === ROUTES.USER
    );
    setShowHeader(location.pathname !== ROUTES.LOGIN);
  }, [location]);

  useEffect(() => {
    const handleWindowBlur = () => {
      if (authState.logged && authState.uid) {
        handleSendSessionDataTo();
      }
    };

    window.addEventListener('blur', handleWindowBlur);

    return () => {
      window.removeEventListener('blur', handleWindowBlur);
    };
  }, [authState, user]);

  return (
    // <TransitionGroup>
    //   <CSSTransition key={location.key} classNames="slide" timeout={500}>
    <>
      {!window.localStorage.getItem('access_token') && (
        <GoogleOneTapLogin
          onError={onErrorGOTL}
          onSuccess={onSuccessGOTL}
          googleAccountConfigs={{
            client_id: process.env
              .REACT_APP_GOOGLE_ONE_TAP_API_CLIENT_ID as string,
          }}
        />
      )}
      {showHeader && <NewHeader />}
      <Switch>
        <Route exact path={ROUTES.LOGIN} component={LoginUserPage} />
        <Route exact path={ROUTES.HOME} component={Home} />
        <Route exact path={ROUTES.DEALER} component={NewDealer} />
        <Route exact path={ROUTES.HISTORY} component={OurHistory} />
        <Route exact path={ROUTES.SOCIALPROGRAM} component={SocialProgram} />
        <Route exact path={`${ROUTES.GARAGE}/:id`} component={Details} />
        <Route exact path={ROUTES.POSTVENTA} component={AfterSale} />
        <Route exact path={ROUTES.GARAGE} component={Garage} />
        <Route exact path={ROUTES.MAP} component={WhereBuyPage} />
        <PrivateRoute exact path={ROUTES.USER} component={UserPage} />
        <Route
          exact
          path={`${ROUTES.BIKE_DETAIL}/:id`}
          component={BikeDetail}
        />
        <Route exact path={ROUTES.SPARE_PARTS} component={SparePartsPage} />
        <Route
          exact
          path={`${ROUTES.FILTERED_GARAGE}/:filter`}
          component={Garage}
        />
        <Route exact path={ROUTES.BLOG + '/:id'} component={Post} />
        <Route exact path={ROUTES.BLOG} component={Blog} />
        <Route path="*" component={Home} />
      </Switch>

      <Footer
        backgrounds={{
          primary:
            location.pathname === ROUTES.SPARE_PARTS ? '#ebe9e8' : '#f6f6f6',
          footerCard: '#fff',
        }}
        horizontalFooter={horizontalFooter}
        showButton={history.location.pathname === ROUTES.HOME}
      />
    </>
    // </CSSTransition>
    // </TransitionGroup>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: any) => {
  return {
    getDealers: () => {
      dispatch(getDealers());
    },
    getParts: () => {
      dispatch(getParts());
    },
    getBikes: () => {
      dispatch(getBikes());
    },
    getBicycles: () => {
      dispatch(getBicycles());
    },
    getServiceProviders: () => {
      dispatch(getServiceProviders());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnimatedSwitch);
