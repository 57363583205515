import { UserRegistrationData } from 'Components/LoginComponent/types/types';
import {
  loginUserWithEmail,
  loginWithGmail,
  registerUserEmail,
  sendRecoveryEmail,
  updateUserPassword,
} from 'Config/Firebase/AuthHelpers';
import {
  convertImageToBase64,
  createDoc,
  deleteDoc,
  getCollection,
  updateDoc,
  uploadImage,
} from 'Config/Firebase/FirebaseHelpers';
import { MyBikeData } from 'Pages/UserPage/types/types';
import {
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILURE,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_REQUEST,
  LOGIN_USER_FAILURE,
  EXISTS_USER_REQUEST,
  EXISTS_USER_SUCCESS,
  EXISTS_USER_FAILURE,
  RECOVERY_USER_REQUEST,
  RECOVERY_USER_SUCCESS,
  RECOVERY_USER_FAILURE,
  LOGIN_GOOGLE_USER_SUCCESS,
  LOGIN_GOOGLE_USER_FAILURE,
  LOGIN_GOOGLE_USER_REQUEST,
  GET_USER_BIKES_USER_REQUEST,
  GET_USER_BIKES_USER_SUCCESS,
  GET_USER_BIKES_USER_FAILURE,
  CREATE_MY_BIKE_USER_REQUEST,
  CREATE_MY_BIKE_USER_SUCCESS,
  UPDATE_MY_BIKE_USER_REQUEST,
  UPDATE_MY_BIKE_USER_SUCCESS,
  UPDATE_MY_BIKE_USER_FAILURE,
  DELETE_MY_BIKE_USER_REQUEST,
  DELETE_MY_BIKE_USER_SUCCESS,
  DELETE_MY_BIKE_USER_FAILURE,
  LOGIN_GOOGLE_ONE_TAP_USER_REQUEST,
  LOGIN_GOOGLE_ONE_TAP_USER_SUCCESS,
  LOGIN_GOOGLE_ONE_TAP_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  SAVE_PRODUCT_REQUEST,
  SAVE_PRODUCT_SUCCESS,
  SAVE_PRODUCT_FAILURE,
  GET_SAVED_PRODUCTS_REQUEST,
  GET_SAVED_PRODUCTS_SUCCESS,
  GET_SAVED_PRODUCTS_FAILURE,
  DELETE_SAVED_PRODUCT_REQUEST,
  DELETE_SAVED_PRODUCT_SUCCESS,
  DELETE_SAVED_PRODUCT_FAILURE,
  SAVE_ALLY_INFO_REQUEST,
  SAVE_ALLY_INFO_SUCCESS,
  CREATE_MY_BIKE_USER_FAILURE,
} from 'Store/Types';
import { v4 as uuidv4 } from 'uuid';

export const registerUser = ({
  password,
  ...userData
}: UserRegistrationData) => {
  return async (dispatch: any) => {
    dispatch({
      type: REGISTER_USER_REQUEST,
    });
    try {
      const { user } = await registerUserEmail(userData.email, password);

      const userCreated = await createDoc('users', {
        ...userData,
        uid: user?.uid,
      });

      dispatch({
        type: REGISTER_USER_SUCCESS,
      });

      setTimeout(() => {
        window.location.reload();
      }, 1000);

      dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: userCreated,
      });

      return userCreated;
    } catch (error) {
      dispatch({
        type: REGISTER_USER_FAILURE,
        payload: JSON.stringify(error.message),
      });
    }
  };
};

export const updateUser = ({ password, ...userData }: any, docId: string) => {
  return async (dispatch: any) => {
    dispatch({
      type: UPDATE_USER_REQUEST,
    });
    try {
      if (password) {
        const updatePassword = await updateUserPassword(
          userData.currentPassword,
          password
        );

        dispatch({
          type: UPDATE_USER_SUCCESS,
        });

        return updatePassword;
      }

      const userCreated = await updateDoc(`users/${docId}`, {
        ...userData,
      });

      dispatch({
        type: UPDATE_USER_SUCCESS,
      });

      return userCreated;
    } catch (error) {
      dispatch({
        type: UPDATE_USER_FAILURE,
        payload: JSON.stringify(error.message),
      });
    }
  };
};

export const loginUser = (email: string, password: string) => {
  return async (dispatch: any) => {
    dispatch({
      type: LOGIN_USER_REQUEST,
    });
    try {
      const { user } = await loginUserWithEmail(email, password);

      const userCreated: any = await getCollection('users', undefined, {
        fieldPath: 'uid',
        opStr: '==',
        value: user.uid,
      });

      if (userCreated && userCreated?.length > 0) {
        dispatch({
          type: LOGIN_USER_SUCCESS,
          payload: userCreated[0],
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else if (userCreated.length === 0) {
        dispatch({
          type: LOGIN_USER_FAILURE,
          payload: 'USER_NOT_FOUND',
        });
        return;
      }

      return userCreated;
    } catch (error) {
      dispatch({
        type: LOGIN_USER_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const loginGoogleUser = () => {
  return async (dispatch: any) => {
    dispatch({
      type: LOGIN_GOOGLE_USER_REQUEST,
    });
    try {
      const { user } = await loginWithGmail();

      const userLogin1: any = await getCollection('users', undefined, {
        fieldPath: 'uid',
        opStr: '==',
        value: user.uid,
      });

      if (userLogin1 && userLogin1?.length > 0) {
        dispatch({
          type: LOGIN_GOOGLE_USER_SUCCESS,
        });
        dispatch({
          type: LOGIN_USER_SUCCESS,
          payload: userLogin1[0],
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        const userLogin2: any = await getCollection('users', undefined, {
          fieldPath: 'email',
          opStr: '==',
          value: user.email,
        });

        if (userLogin2 && userLogin2?.length > 0) {
          dispatch({
            type: LOGIN_GOOGLE_USER_SUCCESS,
          });
          dispatch({
            type: LOGIN_USER_SUCCESS,
            payload: userLogin2[0],
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          dispatch(
            registerUser({
              email: user.email,
              customerType: 'individual',
              name: user.displayName ?? 'Usuario Anonimo',
              provider: 'google',
              password: uuidv4(),
            })
          );
          return null;
        }
      }
    } catch (error) {
      dispatch({
        type: LOGIN_GOOGLE_USER_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const getUserLogged = (uid: string) => {
  return async (dispatch: any) => {
    dispatch({
      type: LOGIN_USER_REQUEST,
    });
    try {
      const userCreated: any = await getCollection('users', undefined, {
        fieldPath: 'uid',
        opStr: '==',
        value: uid,
      });

      if (userCreated && userCreated?.length > 0) {
        dispatch({
          type: LOGIN_USER_SUCCESS,
          payload: userCreated[0],
        });
      } else if (userCreated.length === 0) {
        dispatch({
          type: LOGIN_USER_FAILURE,
          payload: 'USER_NOT_FOUND',
        });
        return;
      }

      return userCreated;
    } catch (error) {
      dispatch({
        type: LOGIN_USER_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const existsUser = (email: string) => {
  return async (dispatch: any) => {
    dispatch({
      type: EXISTS_USER_REQUEST,
    });
    try {
      const foundUser = await getCollection('users', undefined, {
        fieldPath: 'email',
        opStr: '==',
        value: email,
      });

      if (foundUser.length > 0) {
        dispatch({
          type: EXISTS_USER_SUCCESS,
          payload: true,
        });

        return;
      }

      dispatch({
        type: EXISTS_USER_SUCCESS,
        payload: false,
      });

      return foundUser;
    } catch (error) {
      dispatch({
        type: EXISTS_USER_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const recoveryPassword = (email: string) => {
  return async (dispatch: any) => {
    dispatch({
      type: RECOVERY_USER_REQUEST,
    });
    try {
      const sentEmail = await sendRecoveryEmail(email);

      dispatch({
        type: RECOVERY_USER_SUCCESS,
        payload: false,
      });

      return sentEmail;
    } catch (error) {
      dispatch({
        type: RECOVERY_USER_FAILURE,
        payload: error,
      });
    }
  };
};

export const getUserBikes = (userId: string) => {
  return async (dispatch: any) => {
    dispatch({
      type: GET_USER_BIKES_USER_REQUEST,
    });
    try {
      const userBikes: any[] = await getCollection(`users/${userId}/bikes`);

      if (userBikes && userBikes?.length > 0) {
        dispatch({
          type: GET_USER_BIKES_USER_SUCCESS,
          payload: userBikes,
        });
      } else if (userBikes.length === 0) {
        dispatch({
          type: GET_USER_BIKES_USER_FAILURE,
        });
        return;
      }

      return userBikes;
    } catch (error) {
      dispatch({
        type: GET_USER_BIKES_USER_FAILURE,
        payload: error,
      });
    }
  };
};

export const createBike = (userId: string, data: MyBikeData) => {
  return async (dispatch: any) => {
    dispatch({
      type: CREATE_MY_BIKE_USER_REQUEST,
    });
    try {
      const imageInBase64 = await convertImageToBase64(data.photo);

      const { url } = await uploadImage(
        imageInBase64,
        `users/${userId}/bikes/${new Date().getTime()}`
      );

      if (data.defaultBike) {
        const bikeByDefault = await getCollection(
          `users/${userId}/bikes`,
          undefined,
          {
            fieldPath: 'defaultBike',
            opStr: '==',
            value: true,
          }
        );

        if (bikeByDefault.length > 0) {
          await updateDoc(`users/${userId}/bikes/${bikeByDefault[0].id}`, {
            defaultBike: false,
          });
        }
      }

      console.log(`users/${userId}/bikes`);

      console.log({
        ...data,
        photo: url,
      });

      const bikeCreated = await createDoc(`users/${userId}/bikes`, {
        ...data,
        photo: url,
      });

      if (bikeCreated.error) {
        dispatch({
          type: CREATE_MY_BIKE_USER_FAILURE,
          payload: bikeCreated.error.toString(),
        });
      }

      dispatch({
        type: CREATE_MY_BIKE_USER_SUCCESS,
      });

      return bikeCreated;
    } catch (error) {
      dispatch({
        type: CREATE_MY_BIKE_USER_FAILURE,
        payload: error,
      });
    }
  };
};

export const updateBike = (userId: string, docId: string, data: MyBikeData) => {
  return async (dispatch: any) => {
    dispatch({
      type: UPDATE_MY_BIKE_USER_REQUEST,
    });
    try {
      let newUrl = data.photo;
      if (typeof data.photo !== 'string') {
        const imageInBase64 = await convertImageToBase64(data.photo);

        const { url } = await uploadImage(
          imageInBase64,
          `users/${userId}/bikes/${new Date().getTime()}`
        );

        newUrl = url;
      }

      if (data.defaultBike) {
        const bikeByDefault = await getCollection(
          `users/${userId}/bikes`,
          undefined,
          {
            fieldPath: 'defaultBike',
            opStr: '==',
            value: true,
          }
        );

        if (bikeByDefault.length > 0) {
          await updateDoc(`users/${userId}/bikes/${bikeByDefault[0].id}`, {
            defaultBike: false,
          });
        }
      }

      const bikeUpdated = await updateDoc(`users/${userId}/bikes/${docId}`, {
        ...data,
        photo: newUrl,
      });

      dispatch({
        type: UPDATE_MY_BIKE_USER_SUCCESS,
      });

      return bikeUpdated;
    } catch (error) {
      dispatch({
        type: UPDATE_MY_BIKE_USER_FAILURE,
        payload: error,
      });
    }
  };
};

export const deleteBike = (userId: string, docId: string) => {
  return async (dispatch: any) => {
    dispatch({
      type: DELETE_MY_BIKE_USER_REQUEST,
    });
    try {
      const bikeUpdated = await deleteDoc(`users/${userId}/bikes/${docId}`);

      dispatch({
        type: DELETE_MY_BIKE_USER_SUCCESS,
      });

      return bikeUpdated;
    } catch (error) {
      dispatch({
        type: DELETE_MY_BIKE_USER_FAILURE,
        payload: error,
      });
    }
  };
};

export const handleGoogleOneTapLogin = (
  email: string,
  name: string,
  token: string
) => {
  return async (dispatch: any) => {
    dispatch({
      type: LOGIN_GOOGLE_ONE_TAP_USER_REQUEST,
    });
    try {
      const userLogin1: any = await getCollection('users', undefined, {
        fieldPath: 'email',
        opStr: '==',
        value: email,
      });

      if (userLogin1 && userLogin1?.length > 0) {
        dispatch({
          type: LOGIN_GOOGLE_ONE_TAP_USER_SUCCESS,
          payload: {
            uid: userLogin1[0].uid,
            token,
          },
        });

        setTimeout(() => {
          window.location.reload();
        }, 1000);

        return {
          ...userLogin1[0],
          token,
        };
      } else {
        dispatch(
          registerUser({
            email,
            customerType: 'individual',
            name,
            provider: 'google',
            password: uuidv4(),
          })
        );
        return null;
      }
    } catch (error) {
      dispatch({
        type: LOGIN_GOOGLE_ONE_TAP_USER_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const getSavedProducts = (userId: string) => {
  return async (dispatch: any) => {
    dispatch({
      type: GET_SAVED_PRODUCTS_REQUEST,
    });
    try {
      const userFavorites: any[] = await getCollection(
        `users/${userId}/savedProducts`
      );

      dispatch({
        type: GET_SAVED_PRODUCTS_SUCCESS,
        payload: userFavorites,
      });

      return userFavorites;
    } catch (error) {
      dispatch({
        type: GET_SAVED_PRODUCTS_FAILURE,
        payload: JSON.stringify(error.message),
      });
    }
  };
};

export const saveProduct = (
  userId: string,
  productToSave: { productId: string; date: number }
) => {
  return async (dispatch: any) => {
    dispatch({
      type: SAVE_PRODUCT_REQUEST,
      payload: productToSave?.productId,
    });
    try {
      const userCreated = await createDoc(
        `users/${userId}/savedProducts`,
        productToSave
      );

      dispatch({
        type: SAVE_PRODUCT_SUCCESS,
      });

      return userCreated;
    } catch (error) {
      dispatch({
        type: SAVE_PRODUCT_FAILURE,
        payload: JSON.stringify(error.message),
      });
    }
  };
};

export const deleteSavedProduct = (userId: string, productId: string) => {
  return async (dispatch: any) => {
    dispatch({
      type: DELETE_SAVED_PRODUCT_REQUEST,
      payload: productId,
    });
    try {
      const userCreated = await deleteDoc(
        `users/${userId}/savedProducts/${productId}`
      );

      dispatch({
        type: DELETE_SAVED_PRODUCT_SUCCESS,
      });

      return userCreated;
    } catch (error) {
      dispatch({
        type: DELETE_SAVED_PRODUCT_FAILURE,
        payload: JSON.stringify(error.message),
      });
    }
  };
};

export const saveAllyInfo = ({ ...userData }: any, docId: string) => {
  return async (dispatch: any) => {
    dispatch({
      type: SAVE_ALLY_INFO_REQUEST,
    });
    try {
      const userCreated = await updateDoc(`users/${docId}`, {
        ...userData,
      });

      dispatch({
        type: SAVE_ALLY_INFO_SUCCESS,
      });

      dispatch({
        type: UPDATE_USER_SUCCESS,
      });

      return userCreated;
    } catch (error) {
      dispatch({
        type: UPDATE_USER_FAILURE,
        payload: JSON.stringify(error.message),
      });
    }
  };
};
